import React, { useState } from "react"
import { Link } from "gatsby-plugin-intl"

const redirectTo = url => {
  window.location.href = url
}

const SearchPortal = () => {
  const [email, setEmail] = useState("")
  const [formError, setFormError] = useState(null)

  const handleSearch = e => {
    e.preventDefault()
    fetch("https://app.time2client.com/api/search-lawyer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        if (!resultData.portal) {
          setFormError("Nessun avvocato presente nel sistema.")
        } else {
          redirectTo(resultData.portal)
        }
      })
      .catch(error => {
        console.error(error)
        setFormError(JSON.stringify(error))
      })
  }

  return (
    <div className="studio-cover" style={{ height: "100vh" }}>
      <nav className="db">
        <div className="pa3">
          <Link
            className="flex items-center tc ph2 white hover-white-70 dim"
            to="/"
          >
            <img
              src="/images/logo_white_transparent.png"
              style={{ width: "3em" }}
            />
            <span className="dib ml1 white f3 f2-l cinzel">time2client</span>
          </Link>
        </div>
      </nav>

      <div className="bg-black-20 mt2 mv4-ns ba br2 pb4 pb0-ns mw7 center ph2 ph5-ns shadow-5">
        <div className="pv3 mt6 tc">
          <span className="f1 frank white">Login Cliente</span>
        </div>

        <div className="measure center">
          <h3 className="w-100 link-animate fw4 f5 lato">
            <span className="db mv4 f3 frank white">
              Collegati al portale clienti del tuo avvocato dalla sua pagina
              personale.
            </span>
            <span className="db mv4 f3 frank white">
              Se non conosci l'indirizzo del portale clienti del tuo avvocato,
              lo puoi cercare usando il suo indirizzo email.
            </span>
          </h3>
        </div>

        <form className="measure center" onSubmit={handleSearch}>
          <div className="pv2 measure">
            <label className="f4 db mb2 frank fw3 white">
              Email dell'avvocato
              <input
                className="input-reset ba pa2 mb2 lato db w-100 bg-white-70"
                placeholder="avvocato@studiolegale.it"
                type="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                  setFormError("")
                }}
                onFocus={() => {
                  setFormError("")
                }}
              />
            </label>
          </div>

          <div className="flex items-center justify-around mt3 mb5">
            <button
              className="ba br2 min-w-8 bg-white b--black-70 pointer text-color b--text-color hover-b--sec-color hover-white hover-bg-sec-color pa3 shadow-5"
              type="submit"
            >
              <span className="f5 lato" onClick={handleSearch}>
                Vai al portale
              </span>
            </button>
          </div>
        </form>
        <div className="measure center">
          {formError ? (
            <p className="f3 db mb2 frank white">{formError}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SearchPortal
